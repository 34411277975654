<template lang="html">

  <div v-if="user" class="container">

    <div v-if="user.availableTest?.vqTest">
      <div class="validation">
        {{ validation }}
      </div>



      <div class="testFinished" v-if="user.vqTest.finished">
        <h2>Vitality Quotient Upitnik je urađen</h2>
      </div>

      <router-link to="/vqTest" class="router" v-if="!user.vqTest.finished">
        <div class="test">
          <h2>Vitality Quotient Upitnik</h2>
        </div>
      </router-link>

    </div>

    <router-link v-if="user.availableTest?.procenaEdukacije" to="/procenaEdukacije" class="router">
      <div class="test">
        <h2>Procena Edukacije Upitnik</h2>
      </div>
    </router-link>


    <div v-if="user.availableTest?.testLicnosti">

      <router-link to="/testLicnosti" v-if="!user.testLicnosti.finished" class="router">
        <div class="test">
          <h2>NEO PI-R Test Ličnosti</h2>
        </div>
      </router-link>

      <div class="testFinished" v-if="user.testLicnosti.finished">
        <h2>Test Ličnosti je urađen</h2>
      </div>

    </div>

    <div v-if="user.availableTest?.stavoviZaposlenih">

      <router-link to="/stavoviZaposlenih" v-if="!user.stavoviZaposlenihFinished" class="router">
        <div class="test">
          <h2>Upitnik za istraživanje stavova zaposlenih</h2>
        </div>
      </router-link>

      <div class="testFinished" v-if="user.stavoviZaposlenihFinished">
        <h2>Upitnik za istraživanje stavova zaposlenih je uradjen</h2>
      </div>

    </div>

    <div v-if="user.availableTest?.feedbackTest">

      <router-link to="/feedBackTest" class="router">
        <div class="test">
          <h2>360 FeedBack Upitnik</h2>
        </div>
      </router-link>

    </div>


    <div v-if="user.availableTest?.belbinTest">

      <router-link to="/belbin" class="router" v-if="!user.belbinTest.finished">
        <div class="test">
          <h2>Belbinov Upitnik</h2>
        </div>
      </router-link>

      <div class="testFinished" v-if="user.belbinTest.finished">
        <h2>Belbinov Upitnik je urađen</h2>
      </div>

    </div>

    <router-link to="/feedBackIzvestaj" class="router" v-if="user.availableTest?.feedBackReport">
      <div class="test">
        <h2>FeedBack 360 izvestaj za sve zaposlene</h2>
      </div>
    </router-link>


    <router-link to="/performanceEvaluation" class="router" v-if="samoprocena">
      <div class="test">
        <h2>Performance Evaluation Samoprocena</h2>
      </div>
    </router-link>

    <router-link to="/performanceEvaluationViewOnly" class="router" v-if="samoprocenaView">
      <div class="test">
        <h2>Performance Evaluation Pregled Samoprocene</h2>
      </div>
    </router-link>


    <router-link to="/performanceEvaluationKonacna" class="router" v-if="samoprocena || samoprocenaView">
      <div class="test">
        <h2>Performance Evaluation Konačna Procena</h2>
      </div>
    </router-link>

    <router-link to="/konacnaView" class="router" v-if="user.availableTest?.kpiPregled">
      <div class="test">
        <h2>Performance Evaluation Konačna Procena Pregled</h2>
      </div>
    </router-link>

    <router-link to="/performanceEvaluationKonacnaHistory" class="router" v-if="user.availableTest?.kpiHistoryPregled">
      <div class="test">
        <h2>Performance Evaluation Konačna Procena Istorija</h2>
      </div>
    </router-link>

    <router-link to="/psiholoskeProcene" class="router" v-if="user.availableTest?.procenaPregled">
      <div class="test">
        <h2>Procena Kandidata Pregled</h2>
      </div>
    </router-link>

    <router-link to="/customPsiholoskeProcene" class="router" v-if="user.availableTest?.customProcenaView.length > 0">
      <div class="test">
        <h2>Procena Svih Kandidata Pregled</h2>
      </div>
    </router-link>

    <router-link to="/procena-user-admin" class="router" v-if="user.availableTest?.procenaIzmena">
      <div class="test">
        <h2>Procena Kandidata Upis</h2>
      </div>
    </router-link>

    <router-link to="/pdp-user" class="router" v-if="user.availableTest?.pdpView">
      <div class="test">
        <h2>Plan Ličnog Razvoja Pregled</h2>
      </div>
    </router-link>

    <router-link to="/pdp-user" class="router" v-if="user.availableTest?.pdpViewAll">
      <div class="test">
        <h2>Plan Ličnog Razvoja Pregled Svih Zaposlenih</h2>
      </div>
    </router-link>

    <router-link to="/pdp-user" class="router" v-if="user.availableTest?.pdpIzmena">
      <div class="test">
        <h2>Plan Ličnog Razvoja Upis</h2>
      </div>
    </router-link>

    <router-link to="/custom-questionnaire-user" class="router" v-if="activeCustomQuestionnaire && !activeQuestionnaireIsFinished && user.availableTest?.customQuestionnaireView">
      <div class="test">
        <h2>{{ activeCustomQuestionnaire.title }}</h2>
      </div>
    </router-link>
    
      <div class="testFinished" v-if="activeCustomQuestionnaire && activeQuestionnaireIsFinished  && user.availableTest?.customQuestionnaireView">
        <h2>{{ activeCustomQuestionnaire.title }} je uradjen</h2>
      </div>    


     
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      userName: null,
      loading: false,
      lastEval: 0,
      samoprocena: false,
      samoprocenaView: false,
      validation: "",
      activeCustomQuestionnaire: null,
      activeQuestionnaireIsFinished: false,
    }
  },
  computed: mapGetters(['user', 'companys']),
  methods: {
    ...mapActions(['getProfile', 'getCompanies']),
    async getUsername(){
      this.userName = this.user.username
    }
  },
  async created() {
    await this.getProfile();
    await this.getCompanies();
    await this.getUsername();
    this.companys.forEach(company => {
            if (company.name == this.user.type) {
                company.customQuestionnaire.forEach(item => {
                  if(item.active) {
                    this.activeCustomQuestionnaire = item;
                  }
                })
            }

        });
      this.user.customUpitnikSchema.forEach(ele => {
        if(ele.name == this.activeCustomQuestionnaire?.name && ele.finished == true) {
          this.activeQuestionnaireIsFinished = true;
        }
      })
        
    /* console.log("IME USERA:" + this.user.name);
    console.log("test Licnosti: " + this.user.availableTest??.testLicnosti);
    console.log("Stavovi zap: " + this.user.availableTest??.stavoviZaposlenih); */
    let validationName = localStorage.getItem('validationName');
    if (validationName != "" && validationName != null) {
      this.validation = "Uspesno popunjen FeedBack 360 izvestaj za " + validationName + ".";
      localStorage.setItem('validationName', "");
    }

    if (this.user.performanceEvaluation != null) {
      if (this.user.performanceEvaluation.length > 0) {
        this.lastEval = this.user.performanceEvaluation.length - 1;
        this.samoprocena = true;
      }
    }

    if (this.samoprocena) {

      if (this.user.performanceEvaluation[this.lastEval].finished) {

        this.samoprocenaView = true;
        this.samoprocena = false;
      }
    }

  },
  beforeCreate: function () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.body.className = 'profileMobile';
    } else {
      document.body.className = 'profile';
    }

  }

};
</script>

<style lang="css" scoped>
.container {
  position: absolute;
}

/* Sets the containers height and width 
.base-timer {
  position: relative;
  width: 300px;
  height: 300px;

  /* Removes SVG styling that would hide the time label 
  &__circle {
    fill: none;
    stroke: none;
  }

  /* The SVG path that displays the timer's progress 
  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }
}

/* END OF TIMER */



.h2 {
  text-align: center;
}

.test {
  margin: 15px;
  text-align: center;
  background-color: #bbffff;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}

.test:hover {
  background-color: #88cccc;
}

.testFinished {
  margin: 15px;
  text-align: center;
  background-color: #cccccc;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}

.router {
  color: black;
  text-decoration: none;
}

.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  margin:auto;
  margin-top: 100px;
  margin-bottom: 100%;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#pdf{
  animation: flash-animation 5s infinite;
  width: 40px;
}
@keyframes flash-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
